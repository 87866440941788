import { apiClient } from "@/services/ApiClient";

export default {
  // Auth START
  async login(payload) {
    await apiClient.get("/sanctum/csrf-cookie");
    return apiClient.post("/login", payload);
  },
  logout() {
    return apiClient.post("/logout");
  },
  requestCsrfCookie() {
    return apiClient.get("/sanctum/csrf-cookie");
  },
  async authBroadcasting(data) {
    return apiClient.post("/api/broadcasting/auth", data);
  },
  verifyEmail(url) {
    return apiClient.get(url);
  },
  sendEmailVerificationNotification(user) {
    return apiClient.post("/api/email/verification-notification", user);
  },
  resendEmailVerificationMail() {
    return apiClient.post("/api/email/verification-notification");
  },
  forgotPasswordBackend(email) {
    return apiClient.post("/api/forgot-password", email);
  },
  forgotPasswordFrontend(email) {
    return apiClient.post("/forgot-password", email);
  },
  updatePassword(payload) {
    return apiClient.put("/user/password", payload);
  },
  resetPassword(data) {
    return apiClient.post("/reset-password", data);
  },
  updatePasswordAfterFirstLogin(payload) {
    return apiClient.put("/api/users/auth/new-password", payload);
  },
  // Auth END
  // Security START
  confirmPassword(password) {
    return apiClient.post("/api/user/confirm-password", password);
  },
  enableTwoFactorAuth(type) {
    return apiClient.post("/api/user/two-factor-authentication", type);
  },
  resendTwoFactorAuthMail() {
    return apiClient.post("/api/user/resend-two-factor-authentication-code");
  },
  disableTwoFactorAuth() {
    return apiClient.delete("/api/user/two-factor-authentication");
  },
  confirmTwoFactorAuth(code) {
    return apiClient.post(
      "/api/user/confirmed-two-factor-authentication",
      code
    );
  },
  fetchTwoFactorQrCodeSvg() {
    return apiClient.get("/api/user/two-factor-qr-code");
  },
  fetchTwoFactorRecoveryCodes() {
    return apiClient.get("/api/user/two-factor-recovery-codes");
  },
  regenerateTwoFactorRecoveryCodes() {
    return apiClient.post("/api/user/two-factor-recovery-codes");
  },
  twoFactorChallenge(code) {
    return apiClient.post("/api/two-factor-challenge", code);
  },
  // Security END
  // -----------------------------
  // Durchwahlen
  fetchDurchwahlen() {
    return apiClient.get("/api/durchwahlen");
  },
  // Users
  async getAuthUser() {
    return await apiClient.get("/api/users/auth");
  },
  inviteAllUnregisteredUsers(payload = null) {
    return apiClient.post("/api/users/invite-all", payload);
  },
  getUsersByFilter(filters) {
    return apiClient.post("/api/search/users", filters);
  },
  getSingleUser(id) {
    return apiClient.get(`/api/users/user/${id}`);
  },
  updateSingleUser(uuid, payload) {
    return apiClient.post(`/api/users/user/${uuid}`, payload);
  },
  updateInteressent(uuid, payload) {
    return apiClient.put(`/api/users/interessenten/${uuid}`, payload);
  },
  updateSystemUserProfile(payload) {
    return apiClient.put("/api/users/user/profile", payload);
  },
  enableAdressverteilung(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/enableAdressverteilung`);
  },
  disableAdressverteilung(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/disableAdressverteilung`);
  },
  activateUser(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/activate`);
  },
  deactivateUser(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/deactivate`);
  },
  rateUser(data) {
    return apiClient.put(`/api/users/user/${data.uuid}/rating`, data);
  },
  resetUserAssociation(data) {
    return apiClient.post(
      `/api/users/user/${data.uuid}/association/reset`,
      data
    );
  },
  resetTwoFactorAuthentication(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/reset-2fa`);
  },
  resetUserAccount(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/reset`);
  },
  getPersonal(sorting, options) {
    return apiClient.get(
      this.paginationSortingUrl("/api/users/personal", sorting),
      options
    );
  },
  getVerkaeufer() {
    const baseUrl = "/api/users/verkaeufer";
    const query = {
      role: "Verkauf",
    };
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  createNewUser(payload) {
    return apiClient.post("/api/users/user", payload);
  },
  createInteressent(payload) {
    return apiClient.post("/api/users/interessenten", payload);
  },
  fetchInteressenten(query) {
    return apiClient.get("/api/users/interessenten", query);
  },
  fetchInteressentenByTchibo(query) {
    const baseUrl = "/api/users/interessenten/tchibo";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchInteressentenByTchiboKatalog(query) {
    const baseUrl = "/api/users/interessenten/tchibo/katalog";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchInteressentenByTchiboBauberatung(query) {
    const baseUrl = "/api/users/interessenten/tchibo/bauberatung";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchSpam(query) {
    const baseUrl = "/api/users/spam";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchCustomers(config) {
    return apiClient.get("/api/customers", config);
  },
  fetchCustomer(uuid) {
    return apiClient.get(`/api/customers/${uuid}`);
  },
  storeCustomer(payload) {
    return apiClient.post("/api/customers", payload);
  },
  updateCustomer(uuid, payload) {
    return apiClient.put(`/api/customers/${uuid}`, payload);
  },
  // Kunden aus Interessent erstellen
  createCustomerFromProspect(payload) {
    return apiClient.post("/api/customers/from-prospect", payload);
  },
  createApiToken(data, uuid) {
    return apiClient.post(`/api/tokens/create/${uuid}`, data);
  },
  markAsSpam(uuid) {
    return apiClient.post(`/api/users/user/${uuid}/mark-as-spam`);
  },
  /*********************************
   * ANSCHREIBEN
   ********************************/
  generateCustomAnschreiben(payload) {
    return apiClient.post("/api/anschreiben", payload);
  },
  /*********************************
   * RECHNUNGEN
   ********************************/
  fetchInvoices(query) {
    const baseUrl = "/api/invoices";
    return apiClient.get(baseUrl, query);
  },
  fetchInvoiceMeta() {
    return apiClient.get("/api/invoices/meta");
  },
  fetchInvoice(id) {
    return apiClient.get(`/api/invoices/${id}`);
  },
  fetchInvoiceActivities(id) {
    return apiClient.get(`/api/invoices/${id}/activities`);
  },
  createInvoice(payload) {
    return apiClient.post("/api/invoices", payload);
  },
  createInvoicePdf(payload) {
    return apiClient.post("/api/invoices/pdf", payload);
  },
  generateInvoiceOverview(payload) {
    return apiClient.post("/api/invoices/overview", payload);
  },
  updateInvoice(id, payload) {
    return apiClient.put(`/api/invoices/${id}`, payload);
  },
  updateInvoiceStatus(id, payload) {
    return apiClient.put(`/api/invoices/${id}/status`, payload);
  },
  fetchIncomingInvoices(config) {
    const baseUrl = "/api/incoming-invoices";
    return apiClient.get(baseUrl, config);
  },
  createIncomingInvoice(payload) {
    return apiClient.post("/api/incoming-invoices", payload);
  },
  updateIncomingInvoice(payload, id) {
    return apiClient.post(`/api/incoming-invoices/${id}`, payload);
  },
  fetchIncomingInvoice(id) {
    return apiClient.get(`/api/incoming-invoices/${id}`);
  },
  deleteIncomingInvoice(id) {
    return apiClient.delete(`/api/incoming-invoices/${id}`);
  },
  printInvoices(payload, config) {
    return apiClient.post("/api/invoices/print", payload, config);
  },
  /*********************************
   * ZAHLUNGEN
   ********************************/
  fetchPayments(config) {
    return apiClient.get("api/payments", config);
  },
  createPayment(payload) {
    return apiClient.post("/api/payments", payload);
  },
  updatePayment(payload) {
    return apiClient.put(`/api/payments/${payload.id}`, payload);
  },
  deletePayment(id) {
    return apiClient.delete(`/api/payments/${id}`);
  },
  /*********************************
   * STEUERN
   ********************************/
  fetchTaxes() {
    return apiClient.get("/api/taxes");
  },
  /*********************************
   * MAHNUNGEN
   ********************************/
  fetchInvoiceReminders(config) {
    return apiClient.get("/api/invoice-reminders", config);
  },
  createInvoiceReminder(payload) {
    return apiClient.post("/api/invoice-reminders", payload);
  },
  fetchInvoiceReminder(id) {
    return apiClient.get(`/api/invoice-reminders/${id}`);
  },
  updateInvoiceReminder(id, payload) {
    return apiClient.put(`/api/invoice-reminders/${id}`, payload);
  },
  printInvoiceReminders(payload, config) {
    return apiClient.post("/api/invoice-reminders/print", payload, config);
  },
  /*********************************
   * BAUVORHABEN
   ********************************/
  fetchBauvorhaben(config) {
    return apiClient.get("/api/bauvorhaben", config);
  },
  fetchSingleBauvorhaben(id) {
    return apiClient.get(`/api/bauvorhaben/${id}`);
  },
  createBauvorhaben(payload) {
    return apiClient.post("/api/bauvorhaben", payload);
  },
  updateBauvorhaben(uuid, payload) {
    return apiClient.put(`/api/bauvorhaben/${uuid}`, payload);
  },
  generateLieferliste(payload) {
    return apiClient.post("/api/bauvorhaben/lieferliste", payload);
  },
  generateBauvorhabenPlanungsliste(payload, config) {
    return apiClient.post("/api/bauvorhaben/planungsliste", payload, config);
  },
  generateBauvorhabenReferenzliste(payload, config) {
    return apiClient.post("/api/bauvorhaben/referenzliste", payload, config);
  },
  /*********************************
   * BAUVORHABEN EVENTS
   ********************************/
  fetchBauvorhabenEvents(query) {
    return apiClient.get("/api/bauvorhaben-events", query);
  },
  /*********************************
   * BAUVORHABEN EVENT SUBSCRIPTIONS
   ********************************/
  fetchBauvorhabenEventSubscriptions(query) {
    return apiClient.get("/api/bauvorhaben-event-subscriptions", query);
  },
  /*********************************
   * VORGÄNGE
   ********************************/
  fetchVorgaenge(config) {
    const baseUrl = "/api/vorgaenge";
    return apiClient.get(baseUrl, config);
  },
  /*********************************
   * LIEFERANTEN
   ********************************/
  fetchSuppliers(payload) {
    const baseUrl = "/api/suppliers";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, payload));
  },
  fetchSupplier(id) {
    return apiClient.get(`/api/suppliers/${id}`);
  },
  createSupplier(payload) {
    return apiClient.post("/api/suppliers", payload);
  },
  updateSupplier(id, payload) {
    return apiClient.put(`/api/suppliers/${id}`, payload);
  },
  /*********************************
   * KATALOGBESTELLUNGEN
   ********************************/
  getKatalogbestellungen(query) {
    const baseUrl = "/api/cataloguerequests";
    return apiClient.get(baseUrl, query);
  },
  getKatalogbestellungenByDay(date) {
    return apiClient.get(`/api/cataloguerequests/filter?date=${date}`);
  },
  generateAnschreiben(data, config) {
    return apiClient.post("/api/cataloguerequests/anschreiben", data, config);
  },
  fetchKatalogAnschreiben(query) {
    const baseUrl = "/api/cataloguerequests/anschreiben";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  newKatalogbestellung(data) {
    return apiClient.post("/api/cataloguerequests", data);
  },
  removeKatalogbestellung(data) {
    return apiClient.delete(`/api/cataloguerequests/${data.id}`);
  },
  fetchCsv() {
    return apiClient.get("/api/cataloguerequests/csv");
  },
  /*********************************
   * BAUBERATUNGEN
   ********************************/
  fetchBauberatungen(query) {
    const baseUrl = "/api/bauberatungen";
    return apiClient.get(baseUrl, query);
  },
  fetchSingleBauberatung(uuid) {
    return apiClient.get(`/api/bauberatungen/${uuid}`);
  },
  createBauberatung(payload) {
    return apiClient.post("/api/bauberatungen", payload);
  },
  updateBauberatung(id, payload) {
    return apiClient.put(`/api/bauberatungen/${id}`, payload);
  },
  deleteBauberatung(uuid) {
    return apiClient.delete(`/api/bauberatungen/${uuid}`);
  },
  associateSellers(data) {
    return apiClient.post("/api/users/associate", data);
  },
  /*********************************
   * WIEDERVORLAGEN
   ********************************/
  async getWiedervorlagenByUser(id) {
    return await apiClient.get(`/api/wiedervorlagen/${id}`);
  },
  fetchWiedervorlagen(query) {
    const baseUrl = "/api/wiedervorlagen";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  storeWiedervorlage(data) {
    return apiClient.post("/api/wiedervorlagen/new", data);
  },
  updateWiedervorlage(data) {
    return apiClient.put(`/api/wiedervorlagen/${data.wiedervorlage.id}`, data);
  },
  fetchCausedActions(userUuid, perPage = null) {
    if (perPage) {
      return apiClient.get(
        `/api/users/user/${userUuid}/log?perPage=${perPage}`
      );
    }
    return apiClient.get(`/api/users/user/${userUuid}/log`);
  },
  async uploadFiles(data, config) {
    return await apiClient.post(`/api/users/${data.uuid}/upload`, data, config);
  },
  updateUserStatus(payload) {
    return apiClient.put(`/api/users/user/status/${payload.uuid}`, {
      status: payload.status,
    });
  },
  deleteUser(uuid) {
    return apiClient.delete(`/api/users/user/${uuid}`);
  },

  /*********************************
   * EXTERNE IMPORTE
   ********************************/
  fetchExternalImports(query) {
    const baseUrl = "/api/external-imports";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchExternalImport(uuid) {
    return apiClient.get(`/api/external-imports/${uuid}`);
  },

  /*********************************
   * PROVISIONEN
   ********************************/
  fetchCommissionAccounts(query) {
    const baseUrl = "/api/commission-accounts";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  storeCommissionAccount(data) {
    return apiClient.post("/api/commission-accounts", data);
  },
  fetchCommissionAccount(uuid) {
    return apiClient.get(`/api/commission-accounts/${uuid}`);
  },
  fetchCommissions(query) {
    const baseUrl = "/api/commissions";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  storeCommission(data) {
    return apiClient.post("/api/commissions", data);
  },
  updateCommission(data, uuid) {
    return apiClient.put(`/api/commissions/${uuid}`, data);
  },
  fetchCommission(uuid) {
    return apiClient.get(`/api/commissions/${uuid}`);
  },
  deleteCommission(id) {
    return apiClient.delete(`/api/commissions/${id}`);
  },
  fetchCommissionPayouts(query) {
    const baseUrl = "/api/commission-payouts";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  storeCommissionPayout(data) {
    return apiClient.post("/api/commission-payouts", data);
  },
  updateCommissionPayout(data, id) {
    return apiClient.put(`/api/commission-payouts/${id}`, data);
  },
  fetchCommissionPayout(uuid) {
    return apiClient.get(`/api/commission-payouts/${uuid}`);
  },
  deleteCommissionPayout(id) {
    return apiClient.delete(`/api/commission-payouts/${id}`);
  },

  /*********************************
   * NOTIZEN
   ********************************/
  fetchNotes() {
    return apiClient.get("/api/notes");
  },
  storeNewNote(data) {
    return apiClient.post("/api/notes", data);
  },
  deleteNote(noteId) {
    return apiClient.delete(`/api/notes/${noteId}`);
  },

  // Sorting
  fetchSortings() {
    return apiClient.get("/api/sorting");
  },
  storeNewSorting(data) {
    return apiClient.post("/api/sorting", data);
  },
  fetchSingleSorting(sorting) {
    return apiClient.get(`/api/sorting/${sorting}`);
  },
  updateSingleSorting(data) {
    return apiClient.put(`/api/sorting/${data.sorting}`, data.data);
  },
  deleteSingleSorting(sorting) {
    return apiClient.delete(`/api/sorting/${sorting}`);
  },

  //User Status
  async fetchUserStatuses() {
    return await apiClient.get("/api/users/status");
  },
  storeNewUserStatus(status) {
    return apiClient.post("/api/users/status", status);
  },

  // Duplikate
  fetchDuplicatesModel(query) {
    const baseUrl = "/api/duplicates";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchDuplicates(query) {
    const baseUrl = "/api/users/duplicates";
    return apiClient.get(baseUrl, query);
  },
  deleteDuplicate(id) {
    return apiClient.delete(`/api/duplicates/${id}`);
  },
  keepDuplicateAction(data) {
    return apiClient.post(`/api/duplicates/${data.duplicate}`, data);
  },
  mergeDuplicateAction(data) {
    return apiClient.post(`/api/duplicates/${data.duplicate}/merge`, data);
  },

  // Roles & Permissions
  fetchAllRoles() {
    return apiClient.get("/api/roles");
  },
  fetchAllPermissions() {
    return apiClient.get("/api/permissions");
  },
  fetchUserAbilities() {
    return apiClient.get("/api/users/abilities");
  },
  fetchSingleRole(id) {
    return apiClient.get(`/api/roles/${id}`);
  },
  fetchSinglePermission(id) {
    return apiClient.get(`/api/permissions/${id}`);
  },
  createRole(payload) {
    return apiClient.post("/api/roles", payload);
  },
  createPermission(payload) {
    return apiClient.post("/api/permissions", payload);
  },
  updateRole(roleId, payload) {
    return apiClient.put(`/api/roles/${roleId}`, payload);
  },
  updatePermission(permissionId, payload) {
    return apiClient.put(`/api/permissions/${permissionId}`, payload);
  },
  deleteRole(roleId) {
    return apiClient.delete(`/api/roles/${roleId}`);
  },
  deletePermission(permissionId) {
    return apiClient.delete(`/api/permissions/${permissionId}`);
  },

  // Activities / Mitteilungen
  fetchActivities(config) {
    return apiClient.get("/api/notifications", config);
  },
  markAsRead(activity) {
    return apiClient.post(`/api/notifications/${activity}/read`);
  },
  markAllAsRead() {
    return apiClient.post("/api/notifications/read");
  },
  deleteActivity(activity) {
    return apiClient.delete(`/api/notification/${activity}`);
  },
  deleteAll() {
    return apiClient.delete("/api/notifications");
  },

  // Tickets
  fetchTickets(type) {
    if (type) {
      return apiClient.get("/api/tickets?type=" + type);
    } else {
      return apiClient.get("/api/tickets");
    }
  },
  fetchRecentTickets(data) {
    return apiClient.post("/api/tickets/recent", data);
  },
  fetchTicket(ticket) {
    return apiClient.get(`/api/tickets/${ticket}`);
  },
  storeTicket(data) {
    return apiClient.post(`/api/tickets`, data);
  },
  updateTicket(ticket, data) {
    return apiClient.put(`/api/tickets/${ticket}`, data);
  },
  readTicket(data) {
    return apiClient.post("/api/tickets/read", data);
  },
  commentTicket(ticket, data) {
    return apiClient.post(`/api/tickets/${ticket}/comment`, data);
  },
  destroyTicket(ticket) {
    return apiClient.delete(`/api/tickets/${ticket}`);
  },
  fetchTicketCategories() {
    return apiClient.get("/api/tickets/categories");
  },
  fetchTicketCategory(category) {
    return apiClient.get(`/api/tickets/categories/${category}`);
  },
  storeTicketCategory(data) {
    return apiClient.post(`/api/tickets/categories`, data);
  },
  updateTicketCategory(category, data) {
    return apiClient.put(`/api/tickets/categories/${category}`, data);
  },
  destroyTicketCategory(category) {
    return apiClient.delete(`/api/tickets/categories/${category}`);
  },
  fetchTicketStatuses() {
    return apiClient.get(`/api/tickets/statuses`);
  },
  fetchTicketPriorities() {
    return apiClient.get(`/api/tickets/priorities`);
  },
  updateComment(ticket, comment, data) {
    return apiClient.put(`/api/tickets/${ticket}/comment/${comment}`, data);
  },
  deleteComment(ticket, comment) {
    return apiClient.delete(`/api/tickets/${ticket}/comment/${comment}`);
  },

  // Templates (E-Mail, etc.)
  fetchTemplates(query) {
    const baseUrl = "/api/templates";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchSingleTemplate(id) {
    return apiClient.get(`/api/templates/${id}`);
  },
  storeTemplate(data) {
    return apiClient.post("/api/templates", data);
  },
  updateTemplate(data, id) {
    return apiClient.post(`/api/templates/${id}`, data);
  },
  updateBriefTemplate(data, id) {
    return apiClient.put(`/api/templates/${id}`, data);
  },
  deleteTemplate(id) {
    return apiClient.delete(`/api/templates/${id}`);
  },
  sendMail(payload) {
    return apiClient.post("/api/emails", payload);
  },
  sendNewMail(template, user, data) {
    return apiClient.post(`/api/emails/${template}/${user}`, data);
  },
  sendNewCustomMail(data) {
    return apiClient.post("/api/emails/custom", data);
  },

  // Calendar
  fetchCalendar() {
    return apiClient.get("/api/calendar");
  },
  updateCalendar(data) {
    return apiClient.put("/api/calendar", data);
  },
  fetchCalendarEvents(query) {
    const baseUrl = "/api/calendar/events";
    return apiClient.get(baseUrl, query);
  },
  fetchCalendarEventsOwnOnly() {
    return apiClient.get("/api/calendar/events/own");
  },
  storeEvent(data) {
    return apiClient.post("/api/calendar/events", data);
  },
  updateEvent(event) {
    return apiClient.put(`/api/calendar/events/${event.id}`, event);
  },
  deleteEvent(event) {
    return apiClient.delete(`/api/calendar/events/${event.id}`);
  },

  // Stundenzettel
  fetchTimesheets(query, options) {
    const baseUrl = "/api/timesheets";
    return apiClient.get(this.paginationSortingUrl(baseUrl, query), options);
  },
  fetchSingleTimesheet(uuid) {
    return apiClient.get(`/api/timesheets/${uuid}`);
  },
  storeTimesheet(data) {
    return apiClient.post("/api/timesheets", data);
  },
  updateTimesheet(uuid, data) {
    return apiClient.put(`/api/timesheets/${uuid}`, data);
  },
  deleteTimesheet(uuid) {
    return apiClient.delete(`/api/timesheets/${uuid}`);
  },

  // Search
  searchUsers(query) {
    return apiClient.post("/api/users/search", { q: query });
  },
  search(data) {
    return apiClient.post("/api/search", data);
  },

  // Settings
  fetchUserSettings() {
    return apiClient.get("/api/settings/profile");
  },
  updateProfileSettings(data) {
    return apiClient.put(`/api/settings/profile/${data.id}`, data.data);
  },
  fetchInvoiceSettings() {
    return apiClient.get("/api/settings/invoices");
  },
  storeInvoiceSettings(payload) {
    return apiClient.post("/api/settings/invoices", payload);
  },

  // Files
  fetchUserFiles(query) {
    const baseUrl = "/api/files";
    return apiClient.get(baseUrl, query);
  },
  storeUserFiles(payload, config) {
    return apiClient.post("/api/files", payload, config);
  },
  bulkUpload(payload, config) {
    return apiClient.post("/api/files/bulk-upload", payload, config);
  },
  showUserFile(query) {
    const baseUrl = "/api/files/show";
    return apiClient.get(baseUrl, query);
  },
  downloadZip(payload) {
    return apiClient.get("/api/files/download-zip", payload);
  },
  updateFile(payload) {
    return apiClient.post("/api/files/update", payload);
  },
  deleteUserFiles(payload) {
    return apiClient.post("/api/files/delete", payload);
  },
  deleteFile(id) {
    return apiClient.delete(`/api/files/${id}`);
  },
  fetchUserFolders(query) {
    const baseUrl = "/api/folders";
    return apiClient.get(baseUrl, query);
  },
  storeUserFolders(payload) {
    return apiClient.post("/api/folders", payload);
  },
  updateUserFolders(payload) {
    return apiClient.post("/api/folders/update", payload);
  },
  deleteUserFolders(payload) {
    return apiClient.post("/api/folders/delete", payload);
  },
  uploadSingleFile(file) {
    return apiClient.post("/api/files", file);
  },

  // Vacations
  fetchVacations(config) {
    return apiClient.get("/api/vacations", config);
  },
  fetchVacation(id, config) {
    return apiClient.get(`/api/vacations/${id}`, config);
  },
  storeVacation(payload) {
    return apiClient.post("/api/vacations", payload);
  },
  updateVacation(payload) {
    return apiClient.put(`/api/vacations/${payload.id}`, payload);
  },
  deleteVacation(id) {
    return apiClient.delete(`/api/vacations/${id}`);
  },
  fetchVacationAccounts() {
    return apiClient.get("/api/vacation-accounts");
  },
  updateVacationAccounts(payload) {
    return apiClient.post("/api/vacation-accounts/", payload);
  },
  resetVacationAccountDays() {
    return apiClient.post("/api/vacation-accounts/reset");
  },

  // Statistics
  fetchStatisticsOverview() {
    return apiClient.get("/api/statistics/overview");
  },
  fetchJahresumsaetze(params) {
    return apiClient.get("/api/statistics/jahresumsaetze", { params });
  },
  fetchFinanzauswertung(params) {
    return apiClient.get("/api/statistics/finanzauswertung", { params });
  },
  fetchErtragswertung(params) {
    return apiClient.get("/api/statistics/ertragsauswertung", { params });
  },
  fetchFinanzvorschau(params) {
    return apiClient.get("/api/statistics/finanzvorschau", { params });
  },

  // Helpers
  // -----------------------------
  paginationSortingUrl(baseUrl, sortingData) {
    let url = baseUrl + "?";
    if (sortingData) {
      if (sortingData.page) {
        url += `page=${sortingData.page}`;
      } else {
        url += "page=1";
      }
      if (sortingData.perPage && !sortingData.orderBy) {
        url += `&perPage=${sortingData.perPage}`;
      }
      if (sortingData.perPage && sortingData.orderBy) {
        url += `&perPage=${sortingData.perPage}&orderBy=${sortingData.orderBy}&orderDesc=${sortingData.orderDesc}`;
      }

      Object.entries(sortingData).forEach(([key, value]) => {
        if (
          key !== "page" &&
          key !== "perPage" &&
          key !== "orderBy" &&
          key !== "orderDesc"
        ) {
          url += `&${key}=${value}`;
        }
      });
    } else {
      url = baseUrl;
    }
    return url;
  },
  addQueryStringToBaseUrl(baseUrl, query) {
    if (query) {
      let queryString = "";

      Object.entries(query).forEach(([key, value], index) => {
        if (index === 0) {
          queryString += `?${key}=${value}`;
        } else {
          queryString += `&${key}=${value}`;
        }
      });

      return baseUrl + queryString;
    }

    return baseUrl;
  },
};
